import React from 'react';
import { Badge, Card, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Status, StatusFilter, statusToColor, statusToStr } from './status';

interface Game {
    orderNr: string,
    title: string,
    price: string,
    status: number,
    hasImage: boolean,
}

type GameProps = {
    isAdmin: boolean;
};

type GameState = {
    games: Game[];
    filter: StatusFilter;
};

function filterGames(games: Game[], filter: StatusFilter) : Game[] {
    switch (filter) {
        case StatusFilter.None:
            return games;
        case StatusFilter.Empty:
            return games.filter(elem => elem.status === Status.Empty);
        case StatusFilter.Bought:
            return games.filter(elem => elem.status === Status.Bought);
        case StatusFilter.Played:
            return games.filter(elem => elem.status === Status.PlayedAll ||
                                        elem.status === Status.PlayedMel ||
                                        elem.status === Status.PlayedNicoMel);
    }
}

class GameView extends React.Component<GameProps, GameState> {
    state: Readonly<GameState> = {
        games: [],
        filter: StatusFilter.None,
    };

    componentDidMount() {
        fetch("/api/get-all")
            .then(resp => resp.json())
            .then(data => this.setState({games: data.games}));
    }

    reloadGames() {
        if (!window.confirm("Soll die Spieledatenbank wirklich aktualisiert werden?")) {
            return;
        }
    
        fetch("/api/update-database").then(() => {
            fetch("/api/get-all")
                .then(resp => resp.json())
                .then(data => this.setState({games: data.games}));
        });
    }

    changeStatus(game : Game) {
        if (!this.props.isAdmin) {
            return;
        }

        const status = (game.status + 1) % 4;

        fetch(`/api/set-status?order=${game.orderNr}&status=${status}`).then(() => {
            fetch("/api/get-all")
                .then(resp => resp.json())
                .then(data => this.setState({games: data.games}));
        });
    }

    render() {
        const {games, filter} = this.state;
        const {isAdmin} = this.props;
        const filteredGames = filterGames(games, filter);

        const setFilter = (filter: StatusFilter) => {this.setState({filter: filter})};

        return (
            <>
                <Navbar bg="dark" variant="dark" className="mb-3">
                    <Container>
                        <Navbar.Brand>Exit Tracker</Navbar.Brand>
                        <Nav>
                            <Nav.Link active={filter === StatusFilter.None} onClick={() => setFilter(StatusFilter.None)}>Alle</Nav.Link>
                            <Nav.Link active={filter === StatusFilter.Empty} onClick={() => setFilter(StatusFilter.Empty)}>Unbekannt</Nav.Link>
                            <Nav.Link active={filter === StatusFilter.Bought} onClick={() => setFilter(StatusFilter.Bought)}>Gekauft</Nav.Link>
                            <Nav.Link active={filter === StatusFilter.Played} onClick={() => setFilter(StatusFilter.Played)}>Gespielt</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
                <Container className="mb-5">
                    <Row>
                        {filteredGames.map(game => (
                            <Col md="6" lg="3" key={game.orderNr}>
                                <Card className="mb-3">
                                    <Card.Header>
                                        {game.title}
                                        
                                    </Card.Header>
                                    <Card.Body>
                                        <Badge bg="secondary" className="me-1">Bestellnr.: {game.orderNr}</Badge>
                                        <Badge pill bg="primary" className="me-1">{game.price}</Badge>
                                        <Badge 
                                            pill
                                            style={{cursor: isAdmin ? "pointer" : "auto"}}
                                            onClick={() => {this.changeStatus(game)}}
                                            bg={statusToColor(game.status)}
                                        >
                                            {statusToStr(game.status)}
                                        </Badge>
                                    </Card.Body>
                                    {game.hasImage && (
                                        <Card.Img 
                                            variant="bottom" 
                                            src={"/api/get-picture?order=" + game.orderNr} 
                                            alt={"Karton des Exit Spiels"} 
                                        />
                                    )}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
                {isAdmin && (
                    <Navbar bg="light" variant="light" fixed="bottom">
                        <Container>
                            <Nav>
                                <Nav.Link onClick={() => {this.reloadGames()}}>Liste aktualisieren</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                )}
            </>
        );
    }
}

export default GameView;
