import React from 'react';
import { Button, Col, Container, Form, Navbar, Row } from 'react-bootstrap';

type LoginProps = {
    onLogin: (isAdmin: boolean) => void;
};

type LoginState = {
    username: string;
    password: string;
    loading: boolean;
    error: boolean;
};

class Login extends React.Component<LoginProps, LoginState> {
    state: Readonly<LoginState> = {
        username: "",
        password: "",
        loading: false,
        error: false,
    };

    doLogin() {
        this.setState({loading: true});

        const data = {
            username: this.state.username,
            password: this.state.password,
        };

        fetch("/api/login", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            cache: "no-cache",
            mode: 'cors',
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.hasOwnProperty('error')) {
                    this.setState({loading: false, error: true});
                } else {
                    this.props.onLogin(data.admin);
                }
            })
            .catch(() => {
                this.setState({loading: false, error: true});
            });
    }

    render() {
        const { error, loading, username, password } = this.state;

        return (
            <>
                <Navbar bg="dark" variant="dark" className="mb-3">
                    <Container>
                        <Navbar.Brand>Exit Tracker</Navbar.Brand>
                    </Container>
                </Navbar>
                <Container className="mb-5">
                    <Row>
                        <Col
                            md={{span: 6, offset: 3}}
                            lg={{span: 4, offset: 4}}
                        >
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Benutzer</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Benutzer"
                                        value={username}
                                        onChange={(e) => {this.setState({username: e.target.value})}}
                                        disabled={loading} 
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Passwort</Form.Label>
                                    <Form.Control 
                                        type="password"
                                        placeholder="Passwort"
                                        value={password}
                                        onChange={(e) => {this.setState({password: e.target.value})}}
                                        disabled={loading} 
                                    />
                                    {error && (
                                        <Form.Text className="text-danger">
                                            Benutzername oder Passwort ist falsch.
                                        </Form.Text>
                                    )}
                                </Form.Group>

                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    onClick={() => {this.doLogin()}}
                                    disabled={loading}
                                >
                                    Anmelden
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Login;
