import React from 'react';
import { Spinner } from 'react-bootstrap';
import GameView from './GameView';
import Login from './Login';

type AppState = {
    loggedIn: boolean;
    isAdmin: boolean;
    loading: boolean;
};

class App extends React.Component<{}, AppState> {
    state: Readonly<AppState> = {
        loggedIn: false,
        isAdmin: false,
        loading: true,
    };

    componentDidMount() {
        fetch("/api/check-login")
            .then(resp => resp.json())
            .then(data => {
                this.setState({
                    loading: false, 
                    loggedIn: true,
                    isAdmin: data.admin,
                });
            })
            .catch(() => {
                this.setState({loading: false});
            });
    }

    render() {
        const { loggedIn, isAdmin, loading } = this.state;

        if (loading) {
            return (<Spinner animation='border' />);
        }

        if (loggedIn) {
            return (<GameView isAdmin={isAdmin} />);
        } else {
            return (<Login onLogin={(isAdmin) => {this.setState({isAdmin, loggedIn: true})}} />);
        }
    }
}

export default App;
