
export const enum Status {
    Empty = 0,
    Bought = 1,
    PlayedAll = 2,
    PlayedMel = 3,
    PlayedNicoMel = 4,
}

export const enum StatusFilter {
    None,
    Empty,
    Bought,
    Played,
}

export function statusToStr(status: number) : string {
    switch (status) {
        case Status.Empty:
            return "Unbekannt";
        case Status.Bought:
            return "Gekauft";
        case Status.PlayedAll:
            return "Gespielt";
        case Status.PlayedMel:
            return "Gespielt (Melanie)";
        case Status.PlayedNicoMel:
            return "Gespielt (Nico & Melanie)";
    }
    return "???";
}

export function statusToColor(status: number) : string {
    switch (status) {
        case Status.Empty:
            return "danger";
        case Status.Bought:
            return "warning";
        case Status.PlayedAll:
            return "success";
        case Status.PlayedMel:
            return "success";
        case Status.PlayedNicoMel:
            return "success";
    }
    return "";
}
